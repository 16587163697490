import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import ThemeLayout from "../../layout/themeLayout";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import Input from "../../component/Input/Input";
import Button from "../../component/Button/Button";
import Icon from "../../component/Icon/Icon";
import RoundImage from "../../component/Image/RoundImage";
import { PLACEHOLDER } from "../../helpers/constant";
import { useDispatch } from "react-redux";
import { search } from "../../api/search";
import { User } from "../../Widgets/Listing/User";
import { Challenge } from "../../Widgets/Listing/Challenge";
import { Club } from "../../Widgets/Listing/Club";
import { Hunt } from "../../Widgets/Listing/Hunt";
import Spinner from "../../component/Spinner/Spinner";
import InfiniteScroll from "react-infinite-scroller";

const Search = React.memo((props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [results, setResults] = useState([]);
    const pagination = useRef({ current_page: 0 });
    const activeTab = useRef("users");
    const loading = useRef(false);

    let [query, setQuery] = useState(useParams().query || "");

    const renderUsers = () => {
        return results?.map((u, i) => <User key={i} user={u} />);
    };

    const renderChallenges = () => {
        return results?.map((c, i) => <Challenge key={i} challenge={c} />);
    };

    const renderClubs = () => {
        return results?.map((c, i) => <Club key={i} club={c} />);
    };

    const renderHunts = () => {
        return results?.map((h, i) => <Hunt key={i} hunt={h} />);
    };

    const renderResults = useMemo(() => {
        const onEndReach = () => {
            getSearchResults(20, pagination.current.current_page + 1, activeTab.current);
        };

        return (
            <InfiniteScroll
                pageStart={0}
                loadMore={onEndReach}
                hasMore={results?.length < pagination?.current?.total}
                loader={<Spinner key="Loadmore" type="LoadMore" />}
                threshold={50}
                useWindow={true}
            >
                {activeTab.current == "users" && renderUsers()}
                {activeTab.current == "challenges" && renderChallenges()}
                {activeTab.current == "clubs" && renderClubs()}
                {activeTab.current == "hunts" && renderHunts()}
            </InfiniteScroll>
        );
    }, [results, pagination]);

    const getSearchResults = useCallback(
        (per_page = 5, page_no = 1, search_type = "users") => {
            if (pagination.current.current_page > 0 && page_no > pagination.current.last_page) {
                return;
            }

            if (!query) {
                return;
            }
            loading.current = true;
            dispatch(
                search({
                    params: {
                        search_type: activeTab.current,
                        query: query,
                        per_page,
                        page_no,
                    },
                }),
            )
                .then((response) => {
                    loading.current = false;
                    if (response.success) {
                        pagination.current = response.pagination_data;
                        activeTab.current = response.additional;
                        if (response.pagination_data.current_page == 1) {
                            setResults(response.data);
                        } else {
                            setResults((p) => [...p, ...response.data]);
                        }
                    }
                })
                .catch((e) => {
                    loading.current = false;
                    console.log(e);
                });
        },
        [dispatch, activeTab, pagination, query],
    );

    const selectTab = (tab) => {
        activeTab.current = tab;
        pagination.current = { current_page: 0 };
        setResults([]);
        getSearchResults(20, 1, activeTab.current);
    };

    useEffect(() => {
        getSearchResults(20, 1, activeTab.current);
    }, [dispatch]);

    return (
        <ThemeLayout>
            <section>
                <div className="gap">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-8">
                                <div className="search-sec">
                                    <Input
                                        className="uk-input"
                                        name="search"
                                        styleType="floating"
                                        placeholder="Search"
                                        defaultValue={query}
                                        autoComplete="off"
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                setResults([]);
                                                pagination.current = { current_page: 0 };
                                                getSearchResults();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setQuery(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="tab-section">
                                    <div className="tabs-buttons">
                                        <Button
                                            type="button"
                                            className={`btn-sm mr-1 rounded-pill ${activeTab.current != "users" ? "btn-light" : ""}`}
                                            onClick={() => {
                                                selectTab("users");
                                            }}
                                        >
                                            <Icon name="user-alt-2" className="mr-1" />
                                            Users{" "}
                                        </Button>
                                        <Button
                                            type="button"
                                            className={`btn-sm mr-1 rounded-pill ${activeTab.current != "challenges" ? "btn-light" : ""}`}
                                            onClick={() => {
                                                selectTab("challenges");
                                            }}
                                        >
                                            <Icon name="light-bulb" className="mr-1" />
                                            Challenges{" "}
                                        </Button>
                                        <Button
                                            type="button"
                                            className={`btn-sm mr-1 rounded-pill ${activeTab.current != "clubs" ? "btn-light" : ""}`}
                                            onClick={() => {
                                                selectTab("clubs");
                                            }}
                                        >
                                            <Icon name="users" className="mr-1" />
                                            Clubs{" "}
                                        </Button>
                                        <Button
                                            type="button"
                                            className={`btn-sm rounded-pill ${activeTab.current != "hunts" ? "btn-light" : ""}`}
                                            onClick={() => {
                                                selectTab("hunts");
                                            }}
                                        >
                                            <Icon name="briefcase" className="mr-1" /> Hunts{" "}
                                        </Button>
                                    </div>
                                    {results.length > 0 && <div className="tab content mt-3">{renderResults}</div>}
                                    {results.length == 0 && !loading.current && <div className="tab content mt-3">No Results</div>}
                                    {loading.current && <div className="tab content mt-3">Searching...</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ThemeLayout>
    );
});

export default Search;
