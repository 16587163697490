import React, { useState, useEffect } from "react";
import { PLACEHOLDER } from "helpers/constant";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../component/Button/Button";
import RoundImage from "../../component/Image/RoundImage";
import { users as userApi } from "../../api/users";
import SendNotification from "../../helpers/notification";
import styles from "../../pages/Listing/listing.module.css";
export const User = (props) => {
    const auth_user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const [user, setUser] = useState(props.user);
    const followUser = (user) => {
        dispatch(
            userApi({
                method: "POST",
                param_path: user?.id.toString() + "/follow",
            }),
        )
            .then((response) => {
                if (response.success) {
                    setUser(response.data.user);
                    if (response.data.notification) {
                        SendNotification(response.data.notification);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <div className="card my-1 p-2 bg-light border-light rounded">
                <div className="row m-0 d-md-flex d-sm-block text-sm-center align-items-center bd-highlight ">
                    <div className={`col-lg-1 col-md-2 col-sm-12`}>
                        <RoundImage className="img-thumbnail" src={user.profile_pic || PLACEHOLDER} size="50px" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12 detail-sec my-sm-3">
                        <Link to={`/profile/${user.id}`}>
                            <p className="mb-0 text-capitalize text-truncate">{user.name}</p>
                        </Link>
                        <small className="d-block text-muted">{user.intro}</small>
                    </div>
                    <div className={`${styles["action-btns"]} col-lg-4 col-md-3 col-sm-12 text-sm-center text-right  action-btns`}>
                        {!user.is_following && user.id != auth_user.id && (
                            <Button type="button" className="btn btn-inline btn-sm" onClick={() => followUser(user)}>
                                Follow
                            </Button>
                        )}
                        {user.is_following && (
                            <Button type="button" className="btn btn-inline btn-sm" onClick={() => followUser(user)}>
                                Following
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
