import { getSocket } from "../core/socket";

const SendNotification = (n) => {

    const notification = {
        title: n.title,
        text: n.description,
        image: n.image,
        user_id: n.user_id,
        link: n.link,
    }
    getSocket().emit("notify", notification)
};

export default SendNotification