import React, { useState, useEffect, useCallback } from "react";
import { PLACEHOLDER } from "helpers/constant";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../component/Button/Button";
import RoundImage from "../../component/Image/RoundImage";
import SendNotification from "../../helpers/notification";
import { hunts as ApplyJob } from "../../api/hunts";
import { toast } from "react-toastify";
import ConfirmModal from "../../component/ConfirmModal/ConfirmModal";
import styles from "../../pages/Listing/listing.module.css";
import ApplyJobModal from "../../component/ApplyJob/ApplyJobModal";
export const Hunt = (props) => {
    const hunt = props.hunt;
    const [hasApplied, setHasApplied] = useState(hunt.has_applied);
    const dispatch = useDispatch();
    const auth_user = useSelector((store) => store.user.user);
    const [showConfirm, setShowConfirm] = useState(false);

    // const applyJob = useCallback(() => {
    //     if (hasApplied) {
    //         setShowConfirm(true);
    //         return;
    //     }
    //     dispatch(
    //         ApplyJob({
    //             param_path: hunt.id + "/applicants",
    //             method: "POST",
    //         })
    //     )
    //         .then((response) => {
    //             if (response.success) {
    //                 setHasApplied(true);
    //                 SendNotification(response.data.notification);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // });

    const unApply = useCallback(() => {
        dispatch(
            ApplyJob({
                param_path: hunt.id + "/unapply",
                method: "POST",
            })
        )
            .then((response) => {
                if (response.success) {
                    toast.success(response.message);

                    setHasApplied(false);
                    setShowConfirm(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    });
    return (
        <>
            <ConfirmModal
                showModal={showConfirm}
                title={"Confirm"}
                message={`Are you sure you want to withdraw your application from '${hunt?.job_title}'?`}
                onClose={() => setShowConfirm(false)}
                onConfirm={unApply}
            />
            <div className="card my-1 p-2 bg-light border-light rounded">
                <div className="row m-0 d-flex align-items-center bd-highlight ">
                    <div className={`col-md-1`}>
                        <RoundImage
                            className="img-thumbnail"
                            src={hunt?.image || PLACEHOLDER}
                            size="50px"
                        />
                    </div>
                    <div className="col-md-7 detail-sec">
                        <Link to={`/hunt/${hunt?.id}`}>
                            <p className="mb-0 text-capitalize text-truncate">
                                {hunt?.job_title}
                            </p>
                            <small className="d-block text-muted text-capitalize text-truncate">
                                {hunt?.job_description}
                            </small>
                        </Link>
                    </div>
                    <div
                        className={`${styles["action-btns"]} col-md-4 text-right action-btns`}
                    >
                        {auth_user?.id != hunt?.created_by?.id && (
                            <ApplyJobModal job={hunt} />

                            // <Button
                            //     htmlType="submit"
                            //     type="button"
                            //     className={`btn btn-sm`}
                            //     onClick={() => applyJob(hunt?.id)}
                            // >
                            //     {hasApplied ? "Withdraw" : "Apply"}
                            // </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
