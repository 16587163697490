import React, { useState, useEffect, useCallback } from "react";
import { PLACEHOLDER } from "helpers/constant";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../component/Button/Button";
import RoundImage from "../../component/Image/RoundImage";
import SendNotification from "../../helpers/notification";
import { useHistory } from "react-router-dom";
import styles from "../../pages/Listing/listing.module.css";
export const Challenge = (props) => {
    const challenge = props.challenge;
    const linkTo = challenge.status == "submission" ? `/challenge/${challenge.id}/accept` : `/challenges/${challenge.id}/challengers`;
    const auth_user = useSelector((store) => store.user.user);
    const history = useHistory();

    return (
        <>
            <div className="card my-1 p-2 bg-light border-light rounded">
                <div className="row m-0 d-flex align-items-center bd-highlight ">
                    <div className={``}>
                        <RoundImage className="img-thumbnail" src={challenge.thumbnail || PLACEHOLDER} size="50px" />
                    </div>
                    <div className=" detail-sec flex-fill ml-2" style={{ width: "0" }}>
                        <Link to={linkTo}>
                            <p className="mb-0 text-truncate">{challenge.title}</p>
                        </Link>
                        <small className="d-block text-muted text-truncate">{challenge.description}</small>
                    </div>

                    {auth_user.id !== challenge?.created_by?.id && challenge?.status == "submission" && (
                        <div className={`${styles["action-btns"]} text-right action-btns ml-2`}>
                            <Button type="button" onClick={() => history.replace(`/challenge/${challenge.id}/accept`)} className={`btn btn-sm btn-info`} disabled={challenge.has_participated}>
                                {challenge.has_participated ? "Participated" : "Participate"}
                            </Button>
                        </div>
                    )}

                    {auth_user.id !== challenge?.created_by?.id && challenge?.status == "voting" && (
                        <div className={`${styles["action-btns"]} col-md-4 text-right action-btns ml-2`}>
                            <Button
                                type="button"
                                onClick={() => history.replace(`/challenges/${challenge.id}/challengers`)}
                                to={`/challenges/${challenge.id}/challengers`}
                                className={`btn btn-sm btn-info`}
                                disabled={challenge.has_voted}
                            >
                                {challenge.has_voted ? "Voted" : "Vote"}
                            </Button>
                        </div>
                    )}

                    {auth_user.id == challenge?.created_by?.id && (
                        <div className={`${styles["action-btns"]} col-md-4 text-right action-btns ml-2`}>
                            <Button type="button" onClick={() => history.replace(`/challenge/${challenge?.id}/accept`)} to={`/challenge/${challenge?.id}/accept`} className={`btn btn-sm btn-info`}>
                                View
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
