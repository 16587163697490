import React, { useState, useEffect, useCallback, useRef } from "react";
import { PLACEHOLDER } from "helpers/constant";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../component/Button/Button";
import RoundImage from "../../component/Image/RoundImage";
import SendNotification from "../../helpers/notification";
import { useHistory } from "react-router-dom";
import { clubs as clubsApi } from "../../api/clubs";
import styles from "../../pages/Listing/listing.module.css";
export const Club = (props) => {
    const [club, setClub] = useState(props.club);
    const auth_user = useSelector((store) => store.user.user);
    const history = useHistory();

    const dispatch = useDispatch();
    const btnJoin = useRef();
    const btnLeave = useRef();

    const joinClub = useCallback(() => {
        if (club.is_member == 1) {
            btnLeave.current.showLoader(true);
        } else {
            btnJoin.current.showLoader(true);
        }
        dispatch(
            clubsApi({
                param_path: `${club.id}/become_member`,
                method: "POST",
                data: {
                    notes: "ok",
                },
            }),
        )
            .then((response) => {
                if (club.is_member == 1) {
                    btnLeave.current.showLoader(false);
                } else {
                    btnJoin.current.showLoader(false);
                }
                if (response.success) {
                    setClub(response.data);
                }
            })
            .catch((e) => {
                if (club.is_member == 1) {
                    btnLeave.current.showLoader(false);
                } else {
                    btnJoin.current.showLoader(false);
                }
                console.log(e);
            });
    }, [dispatch, club]);

    return (
        <>
            <div className="card my-1 p-2 bg-light border-light rounded">
                <div className="m-0 d-md-flex d-sm-block text-md-left text-sm-center  align-items-center bd-highlight ">
                    <div className={`mr-1`}>
                        <RoundImage className="img-thumbnail" src={club.avatar || PLACEHOLDER} size="50px" />
                    </div>
                    <div className="detail-sec mr-auto mb-sm-2">
                        <Link to={`/clubs/${club.id}`}>
                            <p className="mb-0 text-truncate">{club.title}</p>
                        </Link>
                    </div>

                    {auth_user.id !== club?.created_by?.id && club.is_member == 0 && (
                        <div className={`${styles["action-btns"]} action-btns`}>
                            <Button className="btn btn-sm" ref={btnJoin} type="button" onClick={joinClub}>
                                Join
                            </Button>
                        </div>
                    )}

                    {auth_user.id !== club?.created_by?.id && club.is_member == 1 && (
                        <div className={`${styles["action-btns"]} action-btns`}>
                            <Button className="btn btn-sm" ref={btnLeave} type="button" onClick={joinClub}>
                                Leave
                            </Button>
                        </div>
                    )}

                    {auth_user.id !== club?.created_by?.id && club.is_member == 0.5 && (
                        <div className={`${styles["action-btns"]} action-btns`}>
                            <Button className="btn btn-sm" type="button" disabled>
                                Requested
                            </Button>
                        </div>
                    )}

                    {auth_user.id === club?.created_by?.id && (
                        <div className=" action-btns">
                            <Button className="btn btn-sm" type="button">Manage</Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
